import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

export default function Address({ showCompanyType }) {
  const { site } = useStaticQuery(query);
  const {
    companyType,
    firstName,
    lastName,
    street,
    streetNumber,
    zipCode,
    city,
  } = site.siteMetadata;

  const companyTypeElement = (
    <>
      {companyType}
      <br />
    </>
  );

  return (
    <p>
      {firstName} {lastName}
      <br />
      {showCompanyType && companyTypeElement}
      {street} {streetNumber}
      <br />
      {zipCode} {city}
    </p>
  );
}

Address.propTypes = {
  showCompanyType: PropTypes.Boolean,
};

const query = graphql`
  query {
    site {
      siteMetadata {
        companyType
        firstName
        lastName
        street
        streetNumber
        zipCode
        city
      }
    }
  }
`;
